import React, { useEffect, useState, useRef } from 'react';
import bigDipper from '../assets/images/bdbg.png';
import jupStar from '../assets/images/jupStar.png';
import '../assets/content.css'; 
import { calculateJupiterElement, calculateSaturnElement } from './helperPlanetCal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';


function Content() {
  const [time, setTime] = useState(new Date());
  const originalDayPoint = new Date(1962, 1, 5, 20, 0, 0);
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  // const testDayPoint = new Date(1962, 1, 5, 20, 0, 0);

  // useEffect: Keep updating the clock and big dipper position
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Calculate the rotation angle for the Big Dipper
  const calculateRotationAngle = () => {
    const timeDifference = time.getTime() - originalDayPoint.getTime();
    const rotationAngle =
      ((360 + 360 / 365.2421988) / millisecondsPerDay) * timeDifference;
    return -rotationAngle;
  };

  // ShowDate Function
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', 
                      hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };



  // Calculate the rotation angle for the Jupiter
  const calculateRotationAngleJup = () => {
    const timeDifference = time.getTime() - originalDayPoint.getTime();
    const rotationAngle =
      ((360 + 360 / 4331.87) / millisecondsPerDay) * timeDifference;
    return -rotationAngle;
  };




  // Update Chinese Date
  const [timeForStar, setTimeForStar] = useState(new Date());
  const chineseDateRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeForStar(new Date());
    }, millisecondsPerDay);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [year_sky_stick, month_sky, revoTimeJup, year_groud, month_ground, day_sky, day_ground] 
      = calculateJupiterElement(timeForStar.getFullYear(), 
                    timeForStar.getMonth() + 1, timeForStar.getDate());

  const [year_sky_stick_sat, month_sky_sat, year_groud_sat, revoTimeSat] = calculateSaturnElement(timeForStar.getFullYear(), 
                timeForStar.getMonth() + 1, timeForStar.getDate());

  useEffect(() => {
    if (chineseDateRef.current) {
      chineseDateRef.current.textContent = 
      `今值 ${year_sky_stick_sat}${year_groud_sat} ${month_sky}鎮
      ${year_sky_stick}${year_groud}年 
        ${month_sky_sat}${month_ground}月 ${day_sky}${day_ground}日`;
    } 
  }, [year_sky_stick_sat, year_groud_sat, year_sky_stick, year_groud, month_sky, month_sky_sat, month_ground, day_sky, day_ground]);                          


  // Handle Animation
  const [animateHeader, setAnimateHeader] = useState(false);
  const [animateDipper, setAnimateDipper] = useState(false);
  const [animateRotate, setAnimateRotate] = useState(false);

  // useEffect: Trigger animation when the component mounts
  useEffect(() => {
    setAnimateHeader(true);
    setTimeout(() => {
      setAnimateDipper(true);
      
    }, 2800); // Delay the dipper animation by 500ms
  }, []);

  useEffect(() => {
  if (animateDipper) {
    const timeoutId = setTimeout(() => {
      setAnimateRotate(true);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }
}, [animateDipper]);



  // Overall Structure Arrangement
  return (
    <div id="content" className="Content">
      <header className={`content-header ${animateHeader ? 'animate' : ''}`}>
        <div className="content-container">
          <div className="content-title">
            <div className={`content-description ${animateHeader ? 'animate' : ''}`}>
              <p style={{ fontSize: '1.7em' }}>曆法溯源 歲在甲子 天下歸途</p>
              
              
              <div style={{ display: 'flex', alignItems: 'center' }}>
                

                <div className='tooltipmsg'>
                  <FontAwesomeIcon icon={faCircleInfo} />
                  <span className="tooltiptext" style={{ fontSize: '0.9em' }}>
                    <p>土星紀鎮 木星紀年 太陽紀月 凡360天一年</p>
                    <p>距今木星逆行月餘: {revoTimeJup}</p>
                    <p>距今土星逆行月餘: {revoTimeSat}</p>
                  </span>
                </div>
                &nbsp;
                <p ref={chineseDateRef} className='chinese-date'></p>

              </div>
              
              
     
              
         
              <p className="content-date">
                {formatDate(time)}
              </p> 

            </div>
            <div className={`content-button ${animateHeader ? 'animate' : ''}`}>
              <a href="https://www.ganzhilifa.com/" target="_blank">
                <button className="aboutme-button">
                  查看日曆
                </button>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className={`content-dipper 
                          ${animateDipper ? 'animate' : ''}
                          ${animateRotate ? 'animate-rotate' : ''}`}>
        <img
          src={bigDipper}
          className="big-dipper"
          alt="big dipper"
          style={{ transform: `rotate(${calculateRotationAngle()}deg)` }}
        />
        <img
          src={jupStar}
          className="jup-star"
          alt="Jupiter Star"
          style={{ transform: `rotate(${calculateRotationAngleJup() + 70}deg)` }}
        />
      </div>
      
    </div>
  );
}

export default Content;