import { Footer, Button } from "flowbite-react";
import { BsFacebook, BsGithub, BsInstagram  } from "react-icons/bs";
import { CiLinkedin } from "react-icons/ci";
import 'flowbite/dist/flowbite.min.css';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import "../assets/footer.css";


function FooterContainer() {

  // ShowDate Function
  const formatYear = () => {
    return new Date().getFullYear();
  };


  // Email Validation and Send
  const sendEmail = (e) => {
    e.preventDefault();
    
    const emailFromAddress = e.target.email_from.value.trim();
    const emailMessage = e.target.message.value.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!emailRegex.test(emailFromAddress)) {
      alert("Please enter a valid email address."); 
    }

    else if (emailMessage == "") {
      alert("Please write something before sent!"); 
    }

    else {
      emailjs.sendForm('service_h3b65eu', 'template_mdzrazn', e.target, '79HkXSh_m0v6Iu7XB');
      alert("Your email is well received!");
    }

    
  }


  return (
    <Footer container id="footer" className="footer-div-container">
      <div className="w-full footer-container">
        <div className="form-container grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          
          <div className="w-30 send-container">
            <form className="contact-form" onSubmit={sendEmail}>

              <div className="email-container">
                <p><label htmlFor="emailFrom"> 
                <FontAwesomeIcon icon={faEnvelope} />&nbsp;Your Email 
                </label></p>

                <input type="text" id="emailFrom" name="email_from" />
              </div>
              <br></br>
              <div className="text-container">
              <p>
                <label htmlFor="message">
                <FontAwesomeIcon icon={faMessage} />&nbsp;Message 
                </label>
              </p>
                <textarea name="message" id="message" cols="20" rows="2"></textarea>
              </div>
                <Button className="button-style" type="submit" value="Send"> Send </Button>

            </form>
          </div>

          <div className="more-container w-70 grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            
            <div className="send-intro">
              <p className="send-title">
                <FontAwesomeIcon icon={faPaperPlane} />&nbsp;
                Send me a message
              </p>
              <p>Hello! I am Oscar Yuen, if you wanna know more about the details. Feel free
              to send me an email.</p>
            </div>
            
            <div className="about-container">
              <div className="about-container-1">
                <Footer.Title title="Good Websites" />
                <Footer.LinkGroup col className="">
                  <Footer.Link target="_blank" href="https://maiyuren.com/dict">素典</Footer.Link>
                  <Footer.Link target="_blank" href="https://zi.tools/">字統网</Footer.Link>
                  <Footer.Link target="_blank" href="https://space.bilibili.com/406929249?spm_id_from=333.337.0.0">
                    周鵬說曆法
                  </Footer.Link>
                  <Footer.Link href="https://www.youtube.com/@HermanYeung" target="_blank">
                    Herman Yeung
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div className="about-container-2">
                <Footer.Title title="Projects" />
                <Footer.LinkGroup col>
                  <Footer.Link href="https://github.com/Oscar142857">Github</Footer.Link>
                  <Footer.Link href="https://gitee.com/OscarY">Gitee</Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>

          </div>
        </div>


        <Footer.Divider />
        <div className="sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright by="Oscar Yuen Personal Webpage" year={formatYear()} />
          <div className="mt-3 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="https://www.facebook.com/oscar.yuen.754/" icon={BsFacebook} />
            <Footer.Icon href="https://www.instagram.com/oskahoo.yo/" icon={BsInstagram} />
            <Footer.Icon href="https://github.com/Oscar142857" icon={BsGithub} />
            <Footer.Icon href="https://www.linkedin.com/in/oywk1628/" icon={CiLinkedin} />
          </div>
        </div>
      </div>
    </Footer>
  );
}

export default FooterContainer;