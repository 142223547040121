import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Content from './comp/content';
import Header from './comp/navbarHead';
import Intro from './comp/intro';
import Hobbies from './comp/hobbies';
import SkillSets from './comp/skillSets';
// import LunarCalendar from './comp/lunarCalendar';

import BackToTopButton from './comp/backToTop';
import Footer from './comp/footer';
import reportWebVitals from './reportWebVitals';


// import { lazy, Suspense } from "react";
// const Content = lazy(() => import('./comp/content'));
// const Header = lazy(() => import('./comp/navbarHead'));
// const Intro = lazy(() => import('./comp/intro'));
// const Hobbies = lazy(() => import('./comp/hobbies'));
// const BackToTopButton = lazy(() => import('./comp/backToTop'));
// const Footer = lazy(() => import('./comp/footer'));


// Default CSS of React Bootstrap
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="full-container" style={{ fontFamily: 'Simsun' }}>

      {/* <Suspense fallback={<div>Loading...</div>}> */}
        <div className="whole-container">
          <Header className="header-container"  />
          <Content className="content-container"  />
        </div>

        <div className="middle-container">
          <Intro className="intro-container" />
          <Hobbies className="hobbies-container" />
          <SkillSets className="skillSets-container" />
          <Footer className="foot-container" />
        </div>
        <BackToTopButton />

      {/* </Suspense> */}
    </div>


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
