import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';

const SaturnStudyBlog = ({}) => {
  const [content, setContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch('/blog/saturn_study.txt')
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 250;
  const type = "cn";
  const renderDiv = renderContent(toggleContent, content, num_words, type);
  
  return (
    <div>
      <p>{renderDiv}</p>
        {/* <button>
          Read More
        </button> */}
    </div>
  );
};

export default SaturnStudyBlog;