import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';

const WZQTeamShare = ({}) => {
  const [content, setContent] = useState("");
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch("/blog/sign-lang-sharing.txt")
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent2', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 250;
  const type = "cn";
  const renderDiv = renderContent(toggleContent, content, num_words, type);
  
  return (
    <div>
      <p>{renderDiv}</p>
      <button
        className="blog-button"
        onClick={() => window.open('https://www.hkdeaf.org/', '_blank')}
      >
        聾人協進會
      </button>
    </div>
  );
};

export default WZQTeamShare;