import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';
import '../assets/blogsAll.css';

const FypShare = ({}) => {
  const [content, setContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch('/blog/bc-sharing.txt')
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 100;
  const type = "eng";
  const link = "";

  const renderDiv = renderContent(toggleContent, content, num_words, type, link);
  
  return (
    <div>
      <p>{renderDiv}</p>
      <button
        className="blog-button"
        onClick={() => window.open('https://www.brainchamp.info/', '_blank')}
      >
        BrainChamp
      </button>
    </div>
  );
};

export default FypShare;



// {/* {showModal && <Modal onClose={() => setShowModal(false)} />} */}