import { useState, useRef, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../assets/hobbies.css';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

import CFASTeam from '../assets/images/cfas.png';
import WZQTeam from '../assets/images/wzqteam.jpg';
import JobShadow from '../assets/images/job-shadow.jpg';
import SignLang from '../assets/images/signlang.jpeg';
import SaturnStudy from '../assets/images/study-saturn.jpg';
import LokBook from '../assets/images/lokbook.jpg';
import FypShare from '../assets/images/fyp-share.jpg';
import BCShare from '../assets/images/bc-share.jpg';


import SaturnStudyBlog from '../blog/saturnStudy.js';
import WZQTeamBlog from '../blog/wzqteamShare.js';
import SignLangBlog from '../blog/signLang.js';
import CFASBlog from '../blog/cfasShare.js';
import LokBookBlog from '../blog/lokbookSharing.js';
import JobShadowBlog from '../blog/jobShadow.js';
import FypShareBlog from '../blog/fypShare.js';
import BCShareBlog from '../blog/bcShare.js';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHands, faEarDeaf, faEarthAmericas, faStar,
  faScrewdriverWrench, faBridge, faPeopleGroup, faPenNib, faBriefcase, faSchool,
  faSatelliteDish, faHouseUser, faBrain, faGamepad
} 
        from '@fortawesome/free-solid-svg-icons';


function Hobbies() {
  const [index, setIndex] = useState(0);
  const [isCursorInside, setIsCursorInside] = useState(false);

  const isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  console.log("Mobile: ", isMobile);

  const delayTime = 7 * 1000;

  const handleSelect = (selectedIndex) => {
    if (!isCursorInside) {
      setIndex(selectedIndex);
    }
  };

  const handleMouseEnter = () => {
    setIsCursorInside(true);
  };

  const handleMouseLeave = () => {
    setIsCursorInside(false);
  };

  const getDescription = (index) => {
    switch (index) {
      case 0:
        return [
          <div>
            <p className={`chinese-font ${index === 0 ? 'slide-in' : ''}`}>中國民間藝術坊 CFAS</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faPenNib} />&nbsp;
              <FontAwesomeIcon icon={faPeopleGroup} />
            </div>
          </div>,
          <div>
            <p className={index === 0 ? 'slide-in' : ''}>
                <CFASBlog />
            </p>
          </div>
        ];

      case 1:
        return [
          <div>
            <p className={`eng-font ${index === 1 ? 'slide-in' : ''}`}>HKUST WZQ Team - Voluntary Construction</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faScrewdriverWrench} />&nbsp;
              <FontAwesomeIcon icon={faBridge} />&nbsp;
              <FontAwesomeIcon icon={faPeopleGroup} />
            </div>
          </div>
          ,
          <div>
            <p className={`eng-font-content ${index === 1 ? 'slide-in' : ''}`}>
                <WZQTeamBlog/>
            </p>
          </div>
        ];

      case 2:
        return [
          <div>
            <p className={`eng-font ${index === 2 ? 'slide-in' : ''}`}>BrainChamp - Brain Challenges Startup</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faGamepad} />&nbsp;
              <FontAwesomeIcon icon={faBrain} />&nbsp;
              <FontAwesomeIcon icon={faPeopleGroup} />
            </div>           
          </div>, 
          
          <div>
            <p className={`eng-font-content ${index === 2 ? 'slide-in' : ''}`}>
                <BCShareBlog />
            </p>
          </div>
        ];

      case 3:
        return [
          <div>
            <p className={`chinese-font ${index === 3 ? 'slide-in' : ''}`}>司局長同行計劃 教育局</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faSchool} />&nbsp;
              <FontAwesomeIcon icon={faBriefcase} />
            </div>           
          </div>, 
          
          <div>
            <p className={index === 3 ? 'slide-in' : ''}>
                <JobShadowBlog />
            </p>
          </div>
        ];

      case 4: 
        return [
          <div>
            <p className={`eng-font ${index === 4 ? 'slide-in' : ''}`}>Human Behaviors' Detection Using mmWave Radar</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faSatelliteDish} />&nbsp;
              <FontAwesomeIcon icon={faHouseUser} />&nbsp;
              <FontAwesomeIcon icon={faPeopleGroup} /> 
            </div>
          </div>
          ,
          <div>
            <p className={`eng-font-content ${index === 4 ? 'slide-in' : ''}`}>
                <FypShareBlog/>
            </p>
          </div>
        ];

      case 5:
        return [
          <div>
            <p className={`chinese-font ${index === 5 ? 'slide-in' : ''}`}>彩虹聾協 手語初班心得 舊分享</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faHands} />&nbsp;
              <FontAwesomeIcon icon={faEarDeaf} />
            </div>
            
          </div>,
          <div>
            <p className={index === 5 ? 'slide-in' : ''}>
                <SignLangBlog />
            </p>
          </div>
        ];
        

      case 6:
        return [
          <div>
            <p className={`chinese-font ${index === 6 ? 'slide-in' : ''}`}>土星紀年法 13干29支</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faEarthAmericas} />&nbsp;
              <FontAwesomeIcon icon={faStar} />
            </div>           
          </div>, 
          
          <div>
            <p className={index === 6 ? 'slide-in' : ''}>
                <SaturnStudyBlog />
            </p>
          </div>
        ];

      case 7:
        return [
          <div>
            <p className={`chinese-font ${index === 7 ? 'slide-in' : ''}`}>洛書個人研究。月亮。平氣法</p>
            <div style={{ marginBottom: '10px' }}>
              <FontAwesomeIcon icon={faEarthAmericas} />&nbsp;
              <FontAwesomeIcon icon={faStar} />
            </div>
          </div>,
          <div>
            <p className={index === 7 ? 'slide-in' : ''}>
                <LokBookBlog />
            </p>
          </div>
        ];
      default:
        return null;
    }
  };


  // Width Auto
  const descriptionContainerRef = useRef(null);

  useEffect(() => {
    updateDescriptionContainerWidth();
  }, []);

  const updateDescriptionContainerWidth = () => {
    const descriptionContainer = descriptionContainerRef.current;
    if (descriptionContainer) {
      const descriptionContentWidth = descriptionContainer.scrollWidth;
      const maxWidthPercent = 60;
      const slideContainer = document.getElementById('carousel-container');
      const availableWidthPercent = 100 - slideContainer.clientWidth;
  
      let descriptionWidthPercent = (descriptionContentWidth / availableWidthPercent) * 100;
      descriptionWidthPercent = Math.min(descriptionWidthPercent, maxWidthPercent);
  
      descriptionContainer.style.flex = `0 0 ${descriptionWidthPercent}%`;
      descriptionContainer.style.maxWidth = `${descriptionWidthPercent}%`;
    }
  };

  useEffect(() => {
    updateDescriptionContainerWidth();
  }, [updateDescriptionContainerWidth]);
  

  const [title_description, content_description] = getDescription(index);



  
  return (
    <div className='hobbies-container' id="interest">
        <Carousel
            variant="white"
            className="carousel-container"  id="carousel-container" 
            activeIndex={index} onSelect={handleSelect}>
  
            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={CFASTeam} />
                </div>
            </Carousel.Item>

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Second slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={WZQTeam} />
                </div>
            </Carousel.Item>

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={BCShare} />
                </div>
            </Carousel.Item>    

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={JobShadow} />
                </div>
            </Carousel.Item>     

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={FypShare} />
                </div>
            </Carousel.Item>   

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={SignLang} />
                </div>
            </Carousel.Item>

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="First slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={SaturnStudy} />
                </div>  
            </Carousel.Item>

            <Carousel.Item interval={delayTime}>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <div className='div-test'>
                  <img className='image-setting' src={LokBook} />
                </div>
            </Carousel.Item>            

        </Carousel>
        
        <div
          className='description-container'
          ref={descriptionContainerRef}
          
          onTouchStart={isMobile ? handleMouseEnter : undefined}
          onTouchEnd={isMobile ? handleMouseLeave : undefined}
          onMouseEnter={!isMobile ? handleMouseEnter : undefined}
          onMouseLeave={!isMobile ? handleMouseLeave : undefined}
        >
          {title_description}
          <div className='scrollable-content' style={{ whiteSpace: 'pre-line' }}>
            {content_description}
          </div>
      </div>
        
    </div>
  );
}

export default Hobbies;