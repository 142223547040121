import '../assets/helperTruncated.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';



export const renderContent = (toggleContent, content, number_words, type, link = "") => {
    console.log(content);
    console.log(content.length);
    // const lessThanLimit = (content.length <= number_words) ? true : false;
    // const words = (type == "eng") ? content.split(" ") : content;
    // const truncatedContent = (type == "eng") ? 
    //         words.slice(0, number_words).join(" ") 
    //         : words.slice(0, number_words);
    // const isTruncated = truncatedContent.length <= number_words;

    return (
        <>{content}</>
    );
    // if (lessThanLimit) {
    //     return (
    //         <>{content}</>
    //     );
    // }

    // else if (link !== "") {
    //     return (
    //         <div>
    //             <p>{truncatedContent}...</p>
    //             <a href={link} target="_blank">
    //                 <div className='intro-download'>
    //                     <button className="download-button">
    //                         <FontAwesomeIcon icon={faLink} />
    //                     </button>
    //                 </div>
    //             </a>

    //         </div>
    //     );
    // } else if (isTruncated || type == "eng") {
    //     return (
    //     <div>
    //         <p>{truncatedContent}...</p>
    //     </div>
    //     );
    // } else {
    
    
};


// import React from 'react';

// export const renderContent = (toggleContent, content, number_words, type) => {

//     const words = (type === "eng") ? content.split(' ') : content;
//     const truncatedContent = words[0].slice(0, number_words);
//     const isTruncated = truncatedContent.length <= number_words;

//     if (isTruncated) {
//         return (
//             <div>
//                 <p>{truncatedContent}...</p>
//                 <button onClick={toggleContent}>Read More</button>
//             </div>
//         );
//     } else {
//         return (
//             <>{content}</>
//         );
//     }
// };