import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';
import '../assets/blogsAll.css';

const FypShare = ({}) => {
  const [content, setContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch('/blog/fyp-sharing.txt')
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 100;
  const type = "cn";
  const link = "";

  const renderDiv = renderContent(toggleContent, content, num_words, type, link);

  // Download FYP PPT
  const handleButtonClick = () => {
    const documentUrl = "/pdf/FYP23-24 PPT.pdf";

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = documentUrl;
    downloadLink.setAttribute('download', 'YUEN Wui Kwong - FYP23-24.pdf'); // Set the download filename

    // Append the anchor element to the DOM, click it, and then remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  
  return (
    <div>
      <p>{renderDiv}</p>
      <button
        className="blog-button"
        onClick={handleButtonClick}
      >
        FYP Demo
      </button>
    </div>
  );
};

export default FypShare;



// {/* {showModal && <Modal onClose={() => setShowModal(false)} />} */}