import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import '../assets/Header.css'

function NavbarHead() {
  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} variant="dark"
          className="mb-3 navbar-container" id="main"
        >
          <Container fluid>
            
            <Navbar.Brand href="#main" className="content-font chinese-font" >
            <FontAwesomeIcon icon={faHouse} />
            </Navbar.Brand>


            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />


            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="body-container"
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='content-font-other'>
                  Oscar Yuen Web
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav className="sidebar-setting justify-content-end flex-grow-1 pe-3 content-font-other">
                  {/* <Nav.Link href="#intro">About</Nav.Link>
                  <Nav.Link href="#interest">Interests</Nav.Link>
                  <Nav.Link href="#footer">Contact</Nav.Link> */}
                  <NavDropdown
                    title="Blogs"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#star-study"
                      className='chinese-font'
                    >
                      星象研究 (TBC)
                    </NavDropdown.Item>
                    
                    <NavDropdown.Item href="#calligraphy"
                     className='chinese-font'
                    >
                      隨筆錄 (TBC)
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item href="#secret">
                      Secret (TBC)
                    </NavDropdown.Item>

                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>              
            </Navbar.Offcanvas>


          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavbarHead;