import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDownload } from '@fortawesome/free-solid-svg-icons';
import selfPic from '../assets/images/selfee.jpg';
import '../assets/intro.css';



function Introduction() {

  // Download CV
  const handleButtonClick = () => {
    const documentUrl = "/pdf/CV_it_job.pdf";

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = documentUrl;
    downloadLink.setAttribute('download', 'OscarCV.pdf'); // Set the download filename

    // Append the anchor element to the DOM, click it, and then remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  return (
    <div id="intro" className="Intro-Full">
      <div className="Intro">
          <img src={selfPic} className="self-pic" alt="An icon" />

          <div className="small-container">
            <p className="intro-title">
              <FontAwesomeIcon icon={faUser} />
              &nbsp;
              PERSONAL SUMMARY
            </p>
            <p className="intro-content">
                A student at the Hong Kong University of Science and Technology 
                double-majoring in Electronic Engineering and Computer Science. 
                Strong Mathematic and programming skills. 
                Familiar with basic hardware and software components. 
                Good organization and communication skills with active 
                participation in societies and experience in delivering speeches. 
                Fluent in Cantonese, Mandarin, and English. 
                Skilled in Sign Language. Proficient in Chinese glyph, calender
                and graphic design. 
            </p>
            
            <div className='intro-download'>
              <button className="download-button" onClick={handleButtonClick}>
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;
                More
              </button>
            </div>
            
          </div>
      </div>
    </div>
  );
}

export default Introduction;
