import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';
import '../assets/blogsAll.css';

const SaturnStudyBlog = ({}) => {
  const [content, setContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch('/blog/job-shadow.txt')
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 100;
  const type = "cn";
  const link = "https://cfas.su.hkust.edu.hk/";

  const renderDiv = renderContent(toggleContent, content, num_words, type);
  
  return (
    <div>
      <p>{renderDiv}</p>
      <button
        className="blog-button"
        onClick={() => window.open('https://www.facebook.com/kevinyhyeung/posts/pfbid02JXnUcBUohntoGRGxpBMSo7mZby1u97LRYsDdcX84XkFp4HKvF2mFur1oYGbuULrxl', '_blank')}
      >
        同行計劃
      </button>
    </div>
  );
};

export default SaturnStudyBlog;



// {/* {showModal && <Modal onClose={() => setShowModal(false)} />} */}