export function calculateJulianDay(year, month, day) {
    // Convert the date to Julian Day
    const a = Math.floor((14 - month) / 12);
    const y = year + 4800 - a;
    const m = month + 12 * a - 3;
    const julian_day = day + Math.floor((153 * m + 2) / 5) + 365 * y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400) - 32045;
    return julian_day;
  }
  
export function calculateJupiterElement(year, month, day) {
    const list_A = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'];
    const list_B = ['戊', '己', '庚', '辛', '壬', '癸', '甲', '乙', '丙', '丁'];
    
    const values_A = [36, 36, 36, 36, 36, 36, 36, 36, 36, 35];
    const values_B = [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 31];
    
    const dict_month_A = Object.fromEntries(list_A.map((key, i) => [key, values_A[i]]));
    const dict_month = Object.fromEntries(list_B.map((key, i) => [key, values_B[i]]));
  
    const org_julian = 2437701;
    const julian_day = calculateJulianDay(year, month, day);
  
    let year_idx_sat = Math.floor((julian_day - org_julian) / 359) % list_A.length;
  
    let month_idx_sat = 0;
    let remainder = (julian_day - org_julian) % 359;
    month_idx_sat = Math.floor(remainder / 36);
    let targ_julian_day = org_julian + Math.floor((julian_day - org_julian) / 359) * 359;
    let targ_idx = list_A.indexOf(list_B[year_idx_sat]);
  
    for (let i = 0; i < targ_idx; i++) {
      targ_julian_day += dict_month_A[list_A[i]];
    }
  
    const animal_list = ['寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥', '子', '丑'];
  
    let year_stick_sat = Math.floor((julian_day - org_julian) / 361) % animal_list.length;
  
  
    let year_groun_time = ((julian_day - org_julian) % 365.2421988); 
    const sun_month_data = [30, 30, 30, 31, 31, 30, 30, 30, 31, 31, 30, 31];
    let year_ground_stick = 0;
    for (year_ground_stick = 0; year_ground_stick < sun_month_data.length; year_ground_stick++) {
      year_groun_time -= sun_month_data[year_ground_stick];
      if (year_groun_time <= 0) {
        break;
      } 
    }
  
    const day_ground_dict = ['戌', '亥', '子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉'];
    const day_sky = (julian_day - org_julian)  % list_A.length;
    const day_ground = (julian_day - org_julian)  % day_ground_dict.length;
    
    const revoTime = (targ_julian_day-julian_day > 0) ? targ_julian_day-julian_day : "逆行中";
  
    return [list_A[year_idx_sat], list_A[month_idx_sat], 
            revoTime, animal_list[year_stick_sat], 
                  animal_list[year_ground_stick], list_A[day_sky], day_ground_dict[day_ground]];
}





export function calculateSaturnElement(year, month, day) {
    // Define the arrays
    var skyStickYear = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸', '乾', '坤', '艮'];
    var skyStickMonth = ['戊', '己', '庚', '辛', '壬', '癸', '乾', '坤', '艮', '甲', '乙', '丙', '丁'];

    var monthOrder = [26, 27, 27, 27, 27, 27, 26, 27, 27, 27, 27, 27, 27];
    var dict_month_A = {};
    for (var i = 0; i < skyStickYear.length; i++) {
        dict_month_A[skyStickYear[i]] = monthOrder[i];
    }

    // Convert the date to Julian Day
    var julian_day = calculateJulianDay(year, month, day);
    var org_julian = 2437318;
    // Previous root julian date
    // 2437315, 2437298, 2437310

    // Calculate year index
    var yearStick = Math.floor((julian_day - org_julian) / 349) % skyStickYear.length;
    // b_index = Math.floor((julian_day - org_julian) / 1345) % list_B.length;

    // Calculate month index
    var month_idx_sat = 0;
    var remainder = (julian_day - org_julian) % 349;
    month_idx_sat = Math.floor(remainder / 27);
    var targ_julian_day = org_julian + Math.floor((julian_day - org_julian) / 349) * 349;
    var targ_month_stop = skyStickYear.indexOf(skyStickMonth[yearStick]);

    // Calculate year stick
    for (var index in dict_month_A) {
        if (index < targ_month_stop) {
            targ_julian_day += dict_month_A[index];
        }
    }

    var groundStick = ['燕', '鼠', '牛', '蟹', '鱉', '狸', '豹', '虎', '蝟', '兔',
        '龍', '蛟', '魚', '鱔', '蚯', '蛇', '鹿', '馬', '羊', '雁', '猩', '猿', '猴', '雞', '狗', '狼', '豺', '獾', '豬'];

    var year_stick_sat = Math.floor((julian_day - org_julian) / 371) % groundStick.length;

    const revoTime = (targ_julian_day-julian_day > 0) ? targ_julian_day-julian_day : "逆行中";

    // Return the corresponding elements from A and B arrays
    return [skyStickYear[yearStick], skyStickYear[month_idx_sat], groundStick[year_stick_sat], revoTime];
}